import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Compromiso } from '../models/compromiso.model';

@Injectable({
  providedIn: 'root',
})
export class CompromisoService {
  constructor(private httpClient: HttpClient) { }
  public getCompromisos(
    option: number | null
  ): Observable<{ commitments: Compromiso[] }> {
    let params = new HttpParams();
    let opcion = '';

    switch (option) {
      case 1:
        opcion = '?appliesTo=Aplicaciones&community=Fibras';
        break;
      case 2:
        // Personas o Empresas
        opcion = '&community=Fibras';
        break;
      default:
        break;
    }
    return this.httpClient.get<{
      commitments: any
    }>(
      environment.api_fibras + 'commitments' + opcion,
      { params }
    ).pipe(
      map((response) => ({
        commitments: response.commitments ? response.commitments.sort((a: { title: string; }, b: { title: any; }) => a.title.localeCompare(b.title)) : []
      }))
    );
  }

  public getCompromiso(code: string): Observable<{ compromiso: Compromiso[] }> {
    return this.httpClient.get<{ compromiso: Compromiso[] }>(
      environment.api_fibras + 'commitments/?code=' + code
    )
  }
}

