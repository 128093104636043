import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Compromiso } from 'src/app/shared/models/compromiso.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';

@Component({
  selector: 'app-community-commitments',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityCommitmentsComponent implements OnInit {
  compromisosList: Compromiso[] = [];
  id?: string;

  currentFilter?: string;
  constructor(
    private compromisoService: CompromisoService,
    private route: ActivatedRoute
  ) {}

  
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      if(this.id == 'fibras') {
        this.currentFilter = 'Fibras';
      } else if( this.id == 'impact-trade') {
        this.currentFilter = 'Impact Trade';
      }
      

      this.loadCompromisos();
    });
  }

  loadCompromisos(): void {
    this.compromisoService.getCompromisos(null).subscribe((data: any) => {
      this.compromisosList = data.commitments;
    });
  }


  setFilter(community: string) {
    this.currentFilter = community;
  }
}
