import { Component, Input, OnInit } from '@angular/core';
import { ElementService } from 'src/app/shared/services/element.service';
import { ImpactTradeSidebarComponent } from './component/sidebar/sidebar.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { jwtDecode } from 'jwt-decode';
import { JWTContent } from 'src/app/app.component';

export type SelectedComponentType = {
  id: 'participantes' | 'iniciativas' | 'agenda' | 'fotos' | 'compromisos';
  desc: string;
};

@Component({
  selector: 'app-impact-trade',
  templateUrl: './impact-trade.component.html',
  styleUrls: ['./impact-trade.component.scss'],
})
export class ImpactTradeComponent {
  selectedComponent: SelectedComponentType = {
    id: 'iniciativas',
    desc: 'Iniciativas',
  };

  constructor() {}

  onSelectedComp(comp: SelectedComponentType) {
    this.selectedComponent = comp;
    if (window.innerWidth > 992)
      window.scrollTo({ top: 450, left: 0, behavior: 'instant' });
    else window.scrollTo({ top: 500, left: 0, behavior: 'instant' });
  }
}
