import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { PartnerDialogComponent } from 'src/app/modules/aboutus/component/partner-dialog/partner-dialog.component';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';
import { ParticipanteDetailComponent } from './details/participante-detail.component';
import { Categoria } from 'src/app/shared/models/categoria.model';
import { CategoriasService } from 'src/app/shared/services/categorias.service';

@Component({
  selector: 'app-participantes',
  templateUrl: './participantes.component.html',
  styleUrls: ['./participantes.component.scss'],
})
export class ImpactTradeParticipantesComponent {
  constructor(
    private elementService: ElementService,
    private modalService: NgbModal,
    private keycloakService: KeycloakService,
    private categoriasService: CategoriasService
  ) {}

  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 36;
  totalItems = 0;

  elementos$: Elemento[] = [];
  isMobileWidth = false;

  odsList: Categoria[] = [];
  etapaList: Categoria[] = [];
  dimensionList: Categoria[] = [];
  isFirstLoad = true;

  public filtro = new FormGroup({
    nombre: new FormControl(),
    dimension: new FormControl(),
    copete: new FormControl()
  });

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }
    this.categoriasService.getAll().subscribe((result: any) => {
      this.odsList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'ods'
      )[0].categorias;
      this.dimensionList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'dimension'
      )[0].categorias;
      this.etapaList = result.categorias.filter(
        (cat: Categoria) => cat.tipo_clasificacion === 'etapa'
      )[0].categorias;
    });

    this.filtro.patchValue({
      nombre: '',
      dimension: '0',
      copete: ''
    })

    this.filtrar(false);
  }

  public async filtrar(cargarMas: boolean): Promise<void> {
    this.pageIndex++;
    this.desplazamiento = this.pageIndex * this.limite;
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroParticipantes: ElementoFiltro = new ElementoFiltro();
    filtroParticipantes.rolMinimo = 1;
    filtroParticipantes.estado = 'ACTIVO';
    if (await this.keycloakService.isLoggedIn()) {
      filtroParticipantes.modulo = 'ImpactTrade_Personas'; // Concatenar las opciones con una coma o cualquier otro delimitador
    } else {
      filtroParticipantes.modulo = 'ImpactTrade_Personas';
    }

    filtroParticipantes.nombre = this.filtro.controls['nombre'].value;
    filtroParticipantes.copete = this.filtro.controls['copete'].value;
    // const dimension = this.filtro.controls['dimension'].value;
    // filtroParticipantes.categorias = dimension > 0 ? [dimension] : [];
    this.elementService
    .getAll(
      this.limite,
      this.desplazamiento,
      filtroParticipantes,
      'nombre-asc'
    )
    .subscribe((data) => {
      if (cargarMas) {
        this.elementos$ = [...this.elementos$, ...data.elementos];
      } else {
        this.elementos$ = data.elementos;
        if (!this.isFirstLoad) {
          const elementToScroll = document.querySelector('.filters-section');
          elementToScroll?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
      this.totalItems = data.total;
    });
  }

  public resetear(): void {
    this.filtro.reset({
      nombre: '',
      dimension: '0',
      copete: ''
    });
    this.filtrar(false);
  }

  public loadMore() {
    this.desplazamiento = this.pageIndex * this.limite;
    this.filtrar(true);
  }

  openModal(id: number) {
    const modalRef = this.modalService.open(ParticipanteDetailComponent, {
      centered: true,
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.id = id;
  }
}
