import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';


@Component({
  selector: 'app-commitment-detail',
  templateUrl: './commitment-detail.component.html',
  styleUrls: ['./commitment-detail.component.scss']
})
export class CommitmentDetailComponent implements OnInit {
  constructor(
    private compromisoService: CompromisoService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  idCompromiso!: string;
  compromiso!: any;
  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.idCompromiso = this.router.url.substring(
        this.router.url.lastIndexOf('/') + 1,
        this.router.url.length
      );
    });

    this.compromisoService.getCompromiso(this.idCompromiso).subscribe((data: any) => {

      this.compromiso = data['commitments'][0];
    });
  }



}
