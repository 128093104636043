
<app-header></app-header> 
    
<main style="margin-top: 130px;">
  <!-- routes will be rendered here -->
  <router-outlet ></router-outlet>
</main>

<!-- footer -->
<app-footer></app-footer>
  