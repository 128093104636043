<div class="modal-body">
  <button
    type="button"
    class="btn-close float-end"
    aria-label="Close"
    (click)="modalService.dismiss('Cross click')"
  ></button>
  <div class="row m-0" style="width: 100%">
    <div class="col p-0">
      <div class="profile-wrap">
        <div class="person">
          <figure class="person__photo">
            <img
              *ngIf="elemento['listadoImage']"
              class="img-fluid"
              src="{{ img_base }}{{ elemento.listadoImage }}"
              alt="{{ elemento.nombre }}"
            />

            <img
              *ngIf="!elemento['listadoImage']"
              class="img-fluid"
              src="/assets/img/imgs/default.jpg"
              alt="{{ elemento.nombre }}"
            />
          </figure>
          <div class="person__name__date">
            <span class="person__name">{{ elemento.nombre }}</span>
            <span *ngIf="elemento.fechaInfoInicio" class="person__date"
              >( {{ elemento.fechaInfoInicio | date : "dd/MM/yyyy " }} )</span
            >
          </div>
          <span class="person__rol">{{ elemento.copete }}</span>
          <div class="footer m-0">
            <!-- ToDo: INés_20231209: reemplazar para liberar campos: uncomment l32,33
                              campo fuente cambia a 'email' de multimedia
                              campo infoAdicional cambia a 'telefono' de multimedia-->
            <div class="details mb-3 m-0">
              <span *ngIf="elemento.link"
                ><i class="bi bi bi-globe"></i>&nbsp;<a
                  href="{{ elemento.link }}"
                  class="fw-bold link"
                  target="_blank"
                  >{{ elemento.link }}</a
                ></span
              >
              <span *ngIf="elemento.fuente"
                ><i class="bi bi-envelope-fill"></i> {{ elemento.fuente }}</span
              >
              <span *ngIf="elemento.infoAdicional" class="phone"
                ><i class="bi bi-phone px-1"></i>
                <span innerHtml="{{ elemento.infoAdicional }}"></span>
              </span>
              <span *ngIf="elemento['linkedin']">
                <a
                  href="{{ elemento['linkedin'] }}"
                  target="_blank"
                  class="linkedin"
                  ><i class="bi bi-linkedin"></i
                ></a>
              </span>
              <!-- <span *ngIf="elemento.email"><i class="bi bi-envelope-fill"></i> {{elemento.email}}</span> -->
              <!-- <span *ngIf="elemento.telefono"><i class="bi bi-phone"></i> {{elemento.telefono}}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
