import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isSuscriptor = false;
  isLoggedIn = true;
  public user!: Keycloak.KeycloakProfile;
  isNavbarOpen = false;
  isAssociateActive = false;
  esSuscriptor: boolean = false;
  tieneSuscripcionesPendientes: boolean = false;
  showMagicButton = false;
  token: string = '';
  getButtonClass(): string {
    return this.esSuscriptor ? 'loggedin-button' : 'associate-button';
  }

  public routes: {
    title: string;
    link: string;
    href: boolean;
    restricted: boolean;
  }[] = [
    { title: 'Nosotros', link: '/aboutus', href: false, restricted: false },
    { title: 'Comisiones', link: '/comisiones', href: false, restricted: true },
    { title: 'Proyectos', link: '/proyects', href: false, restricted: false },
    {
      title: 'Comunidades',
      link: '/communities',
      href: false,
      restricted: false,
    },
    { title: 'Sincronía', link: '/sincronia', href: false, restricted: false },
    {
      title: 'Compromisos',
      link: '/commitments',
      href: false,
      restricted: false,
    },
    {
      title: 'Campus',
      link: 'https://www.campusetraining.com/course/index.php',
      href: true,
      restricted: false,
    },
    { title: 'Alianzas', link: '/alliances', href: false, restricted: false },
    { title: 'Novedades', link: '/news', href: false, restricted: false },
    { title: 'Planes', link: '/market', href: false, restricted: true },
    { title: 'Contacto', link: '/contact', href: false, restricted: false },
  ];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private keycloakService: KeycloakService,
    private renderer: Renderer2,
    private el: ElementRef,
    private suscripcionService: SuscripcionService,
    private modalService: NgbModal,
  ) {
    this.keycloakService.loadUserProfile().then(u => {
      this.user = u;
      this.showMagicButton = this.checkUserInList(u);
    });

    this.keycloakService.getToken().then(token => (this.token = token));
  }

  ngOnInit(): void {
    this.suscripcionService.isSuscriptor().subscribe(isSuscriptor => {
      this.esSuscriptor = isSuscriptor;
      this.updateRoutes();
    });
    this.suscripcionService
      .hasPendingSuscription()
      .subscribe(hasPendingSuscription => {
        this.tieneSuscripcionesPendientes = hasPendingSuscription;
      });
  }

  getDisplayableRoutes() {
    return this.routes.filter(r => {
      if (!r.restricted) {
        return r;
      }
      return this.esSuscriptor && r;
    });
  }

  buttonClick() {
    if (this.esSuscriptor) {
      if (this.suscripcionService.memberId != null) {
        window.open(
          `${environment.url_sincronia}user_id=${this.suscripcionService.memberId}&token=${this.token}`,
          '_blank',
        );
      } else if (this.suscripcionService.idClienteConfirmado == null) {
        this.openModal(
          'Aún no tiene aprobado el usuario para acceder a la plataforma. Estará disponible a la brevedad.',
        );
      } else {
        window.open(
          `${environment.url_sincronia}user_id=${this.suscripcionService.idClienteConfirmado}&token=${this.token}`,
          '_blank',
        );
      }
    } else if (this.tieneSuscripcionesPendientes) {
      this.openModal(
        'Usted ya tiene una suscripcion hecha en estado pendiente de verificacion. Pronto le enviaremos novedades de la misma y como proseguir.',
      );
    } else {
      this.router.navigate(['/market']);
    }
  }

  // async checkLoginStatus() {
  //   this.isLoggedIn = await this.keycloakService.isLoggedIn();
  //   this.updateRoutes();
  // }

  updateRoutes() {
    const proyectosIndex = this.routes.findIndex(
      route => route.link === 'proyects',
    );
    if (proyectosIndex !== -1) {
      this.routes[proyectosIndex].title = this.esSuscriptor
        ? 'Proyectos e Iniciativas'
        : 'Proyectos';
    }
  }

  focusElem(event: any, variable: boolean): void {
    const currentActiveElements = this.el.nativeElement.querySelectorAll(
      '.active-link, .active-associate',
    );

    currentActiveElements.forEach((element: any) => {
      this.renderer.removeClass(element, 'active-link');
      this.renderer.removeClass(element, 'active-associate');
    });

    if (variable) {
      const targetClass = event.currentTarget.classList.contains('associate')
        ? 'active-associate'
        : 'active-link';
      this.renderer.addClass(event.currentTarget, targetClass);
    }
  }

  // focusElem2(event: any): void {
  //   const currentActiveElements =
  //     this.el.nativeElement.querySelectorAll('.active-link');

  //   currentActiveElements.forEach((element: any) => {
  //     if (element !== event.currentTarget) {
  //       this.renderer.removeClass(element, 'active-link'); // Cambié 'active-main' por 'active-link'
  //     }
  //   });

  //   this.renderer.addClass(event.currentTarget, 'active-associate');
  // }

  closeNavbar() {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const offcanvasNavbar = document.querySelector('#offcanvasNavbar');
    const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');

    if (navbarToggler && offcanvasNavbar && offcanvasBackdrop) {
      navbarToggler.classList.add('collapsed');
      offcanvasNavbar.classList.remove('show');
      offcanvasBackdrop.classList.remove('show');

      document.body.style.overflow = 'auto';
    }
    this.isNavbarOpen = false;
  }

  public login() {
    this.keycloakService.login({
      idpHint: environment.keycloak_idpHint,
      locale: 'es',
    });
  }

  public logOut(redirect?: string | undefined) {
    this.keycloakService.logout(redirect);
  }

  public goPayments(redirect?: string | undefined) {
    this.router.navigate(['/payments']);
  }

  public goInvoices(redirect?: string | undefined) {
    this.router.navigate(['/invoices']);
  }

  public register() {
    this.keycloakService.register({ idpHint: environment.keycloak_idpHint });
  }

  public onClickLink(e: MouseEvent) {
    this.focusElem(e, true);
    setTimeout(() => this.closeNavbar(), 200);
    // this.closeNavbar();
    // this.buttonClick();
  }

  openModal(texto: string) {
    const modalRef = this.modalService.open(ModalDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.texto = texto;
  }

  //METODO SOLO PARA CAMBIAR Y PROBAR MODOS - QUITAR LUEGO
  onOffSuscriptor() {
    if (this.isSuscriptor) {
      this.suscripcionService.becameSuscriptor(false);
      this.esSuscriptor = false;
    } else {
      this.suscripcionService.becameSuscriptor(true);
      this.esSuscriptor = true;
    }
  }

  private checkUserInList(user: any) {
    if (user && user.email) {
      let emails = [
        'leonardo.mena@pyxis.tech',
        'juan.machado@pyxis.tech',
        'virginia.delossantos@pyxis.tech',
        'martin.escardo@quanam.com',
        'ines.fernandez@quanam.com',
        'german.miraglia@quanam.com',
        'enrique.delfino@quanam.com',
        'hoyver.v@gmail.com',
        'leonardo.schmittner@pyxis.tech',
        'edelgado@quanam.com',
        'juan.castro@quanam.com',
        'gonzalo.rosano@quanam.com',
        'jose.marzoa@quanam.com',
      ];

      let lowerCaseEmailToCheck = user.email.toLowerCase();
      for (let email of emails) {
        if (email.toLowerCase() === lowerCaseEmailToCheck) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
}
