import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iniciativas',
  templateUrl: './iniciativas.component.html',
  styleUrls: ['./iniciativas.component.scss'],
})
export class ImpactTradeIniciativasComponent {
  constructor(
    private elementService: ElementService,
    private keycloakService: KeycloakService,
    private suscripcionService: SuscripcionService
  ) {}

  img_base = environment.img_base;
  isLoggedIn = false;
  esSuscriptor: boolean = false;
  pageIndex = 0;
  desplazamiento = 0;
  limite = 1000;
  totalItems = 0;
  elementos$: Elemento[] = [];

  public filtroUI = new FormGroup({
    nombre: new FormControl(),
  });

  ngOnInit() {
    this.suscripcionService.isSuscriptor().subscribe((result) => {
      this.esSuscriptor = result;
    });
    this.checkLoginStatus();
    this.filtrar(false);
  }

  checkLoginStatus() {
    this.keycloakService.isLoggedIn().then((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  public filtrar(cargarMas: boolean): void {
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroProyectos: ElementoFiltro = new ElementoFiltro();
    filtroProyectos.estado = 'activo';
    filtroProyectos.modulo = 'ImpactTrade_Iniciativas';
    filtroProyectos.categorias = [];

    Object.keys(this.filtroUI.value).forEach((key, index) => {
      if (key.includes('nombre')) {
        (filtroProyectos as any)[key] = (this.filtroUI.value as any)[key];
      }
    });
    this.elementService
      .getAll(this.limite, this.desplazamiento, filtroProyectos, 'nombre-asc')
      .subscribe((data) => {
        if (cargarMas) {
          (data as any)['elementos'].forEach((element: Elemento) => {
            this.elementos$.push(element);
          });
        } else {
          this.elementos$ = (data as any)['elementos'];
        }
        this.totalItems = (data as any)['total'];
      });
    this.pageIndex++;
  }
}
